@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "libs/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";

body {
  background: #ECECEC;
  font-family: "Helvetica", "Helvetica Neue", "Luxi Sans", "DejaVu Sans", Tahoma, "Hiragino Sans GB", STHeiti, "Microsoft YaHei";
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

$mainColor: #90ef7f;
$boldColor: #7c82ff;

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0)
  }
  20%,
  60% {
    transform: rotate(-25deg)
  }
  40%,
  80% {
    transform: rotate(10deg)
  }
}



section {
  margin: 40px 0 0;
}




.tab-content {
  padding: 20px 60px 40px 60px;
  background: #fff; 
}

li {
  line-height: 1.5;
}

a {
    color: $boldColor;

    &:hover {
        color: $mainColor;
    }
}

header {
  padding: 1em 0;
  background: #47475c;
  box-shadow: 0 0 3px 3px rgba(255, 255, 255, .5);
  img{
    display: inline-block;
    height: 80px;
    line-height: 80px;
    margin: -8px 0 0 -32px;
  }

  h1 {
    font-size: 20px;
    // margin:1em 1em 0;
    display: inline-block;
    color: #fff;
  }

  
  nav {
    display: inline-block;
    float: right;
    position: relative;
    top: 20px;
  }
  nav li {
    display: inline-block;
  }


  nav li a {
    color: #aaa;
    display: block;
    padding: 6px 10px;
    text-decoration: none;
  }

  nav li a:hover, nav li.active a {
    color: #fff;
    text-decoration: none;
  }

  

}

.nav-tabs >li >a {
  border-radius: 0;
}

.container {
  position: relative;
}

.panel-title {
  font-size: 14px;
  font-weight: bold;
}

input, select {
  outline: none;
}
/*.description {
  font-size: 20px;
  margin-left: 20px;
}*/

.selector {
  background-color: #fff;
  padding: 25px 40px;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
}


.protocol {
  // float: right;
  // margin: -40px 30px 0;
  margin-bottom: 20px;
  .btn {
    border-radius: 0;
    padding: 5px 13px;
    outline: none;
    &.active {
      background-color: $boldColor;
      color: #fff;
      border-color: $boldColor;
    }
    &:focus {
      outline: none;
    }
  }
  
}

.domain select {
  padding: 4px 6px;
}





// .domain {
//   float: right;
//   margin-right: 30px;
//   margin-top: 20px;
// }

.nav-tabs {
    background: #fff;
    padding: 20px 30px 0;
    li a:hover {
      background: none;
      border-color: #fff #fff #ddd;
    }

}
.product {
   min-height: 400px;
  .product-header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 10px;
    position: relative;
    .info {
        margin-top: 10px;
        li {
            line-height: 2;
        }
    }
  }
  .product-versions {
    /*position: absolute;
    bottom: 20px;
    right: 0;*/
    select {
      min-width: 200px;
      padding: 4px 6px;
    }
  }
  .version-specific {
    ul {
      padding: 0;
      list-style: none;
    }
    li {
      display: inline-block;
    }
  }
  .product-files {
    h5 {
      font-weight: bold;
    }
    ul {
      list-style: none;
      padding-left: 1em;
    }
    li {
      position: relative;
      
      span {
        padding: 8px 10px;
        margin-bottom: 0;
        display: inline-block;
        cursor: pointer;
        position: relative;
        z-index: 1;
      }

      .copy {
        display: none;
        position: absolute;
        left: 0px;
        top: 2px;
        background: #fff;
        z-index: 10;
      }

      .btn {
        color: #333;
        text-decoration: none;
        border: 1px solid #fff;
        padding: 4px 6px;
        font-size: 12px;
        border-radius: 3px;
        outline: none;
        margin-top: -2px;
        &:hover {
          color: #006cee;
        }
      }
      .btn-url {
        margin-left: 20px;
      }

      &:hover .copy{
        display: inline-block;
      }
      input {
        display: inline-block;
        background-color: #f5f5f5;
        padding: 5px 9px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        border: 1px solid #ccc;
        outline: none;
      }
    }
  }
}


.version-list {
  position: relative;
  top: 50px;
}




/*.bg-danger {
  padding: 30px;
  margin-bottom: 30px;
}

.comments-title {
  margin-top: 50px;
}

.comments {
  margin: 40px 20px 0 20px;
  background-color: #fff;
}*/

footer {
  margin:2em 0;
  font-size: 12px;
  p {
    text-align: center;
    margin-bottom: 0;
  }
  a {
    color: #333;
    &:hover,
    &:focus {
      color: $boldColor;
      text-decoration: none;
    }
  }
}

article {
  background-color: #fff;
  padding: 40px 80px;
}

article li {
  line-height: 2;
}

.article-header {
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid #ccc;
}

.article-header h1 {
  font-size: 26px;
}

.article-header .input-warpper {
  position: relative;
}

.article-header .drilldown {
  position: absolute;
  /*top: 24px;*/
  z-index: 1000;
  left: 0;
  right: 4px;
  background-color: #f9f9f9;
  border: 1px solid #66afe9;
  border-top: none;
  max-height: 300px;
  overflow-y: auto;
}

.article-header .drilldown ul {
  padding-left: 0;
  margin-bottom: 0;
}

.article-header .drilldown li {
  list-style: none;
  text-align: left;
  padding: 6px 10px;
  cursor: pointer;
}

.article-header .drilldown li:hover {
  background-color: #66afe9;
  color: #fff;
}

.article-header input {
  display: inline-block;
  border-radius: 0;
  width: 60%;
  border: 1px solid #006cee;
}

.article-header input:focus {
  box-shadow: none;
}

.article-header .btn-default {
  color: #fff;
  border-radius: 0;
  position: relative;
  top: -1px;
  left: -4px;
  padding: 6px 26px;
  background-color: #006cee;
  border-color: #006cee;
}

.installed li {
  padding-left: 10px;
  cursor: pointer;
}

.installed li.active {
  color: #006cee;
  font-weight: bold;
}

.detail {
  background-color: #f9f9f9;
  border: 1px solid #ececec;
  border-radius: 3px;
}

.detail input {
  width: 100%;
  border: none;
  background: none;
}

#checkUpdate {
  font-size: 12px;
  float: right;
  position: relative;
  top: -10px;
}

.maps {
  a {
    color: #66b3ff;
    font-size: 12px;
  }
  a:hover {
    color: $boldColor;
  }
}

.css-version {
  font-weight: normal;
  margin: 10px 0;
  input {
    margin-left: 10px;
  }
}

@media (max-width:500px) {
  .github-corner:hover .octo-arm {
    animation: none
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out
  }
}

// hide slogan while viewpoint is to small
@media screen and (max-width: 800px) {
  header .slogan {
    display: none;
  }
}